<template>
  <div class="main__content">
    <div class="table__header_bar">
      <InputSearch isFilter @detectSearchValue="searchTableHandler" />
      <Button
        isControl
        iconName="plus"
        class="add__user-btn"
        @click.native="createCertificationAgencyHandler"
      >
        {{ $t('sections.certification-agency_to_register') }}
      </Button>
    </div>
    <Table
      v-if="getTableOfCertificationAgenciesAccessAdmin.table"
      :content="tableOfCertificationAgenciesAccessAdmin"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import Table from '@/elements/Table';
import { mapGetters } from 'vuex';

export default {
  components: {
    Table,
  },

  data() {
    return {};
  },
  
  computed: {
    ...mapGetters(['getTableOfCertificationAgenciesAccessAdmin']),

    tableOfCertificationAgenciesAccessAdmin() {
        return this.getTableOfCertificationAgenciesAccessAdmin.table.items.map(row => {
          row.certification_agency_full_name = row.full_name

          return row
        })
    }
  },

  created() {
    this.$store.dispatch('getTableOfCertificationAgenciesAccessAdmin');
  },

  methods: {
    tableHandler() {
      this.$store.dispatch('getTableOfCertificationAgenciesAccessAdmin');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfCertificationAgenciesAccessAdmin');
    },

    createCertificationAgencyHandler() {
      this.$router.push({
        name: 'access-administrator_certification-agency-create-user',
        params: { activity: 'create' },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.add__user-btn
  width: 400px
  font-size: 16px
  @include s
    font-size: 14px
    width: auto
  @include xs
    font-size: 13px
    line-height: 16px
    text-align: center
</style>
